import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useScrollAnimation, fadeInUpVariants, staggerChildrenVariants } from '../hooks/useScrollAnimation';
import { usePosts } from '../hooks/usePosts';

const Blog: React.FC = () => {
  const { ref, controls } = useScrollAnimation();
  const { posts, loading, error } = usePosts(true); // Only show posts marked for homepage

  return (
    <section id="blog" className="py-32">
      <motion.div
        ref={ref}
        initial="visible"
        animate={controls}
        variants={staggerChildrenVariants}
        className="container mx-auto px-4 max-w-7xl"
      >
        <motion.div variants={fadeInUpVariants} className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Latest Insights
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            Thoughts and perspectives on technology, design, and innovation.
          </p>
        </motion.div>

        <motion.div
          variants={staggerChildrenVariants}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12"
        >
          {posts.map((post) => (
            <motion.article
              key={post.id}
              variants={fadeInUpVariants}
              className="group bg-white dark:bg-gray-800 rounded-2xl overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1"
            >
              <Link 
                to={`/blog/${post.slug}`}
                className="block focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 dark:focus:ring-offset-gray-900 rounded-2xl"
              >
                <div className="aspect-[16/9] overflow-hidden">
                  <img
                    src={post.image}
                    alt={post.title}
                    className="w-full h-full object-cover transform transition-transform duration-700 group-hover:scale-105"
                  />
                </div>
                <div className="p-8 space-y-4">
                  <div className="flex items-center justify-between text-sm text-gray-600 dark:text-gray-400">
                    <span className="px-3 py-1 bg-purple-100 dark:bg-purple-900/30 text-purple-600 dark:text-purple-400 rounded-full">
                      {post.category}
                    </span>
                    <span>{post.readTime}</span>
                  </div>
                  <h3 className="text-2xl font-semibold text-gray-900 dark:text-white group-hover:text-purple-600 dark:group-hover:text-purple-400 transition-colors">
                    {post.title}
                  </h3>
                  <p className="text-gray-600 dark:text-gray-300">
                    {post.excerpt}
                  </p>
                  <div className="flex items-center justify-between pt-4">
                    <time className="text-sm text-gray-500 dark:text-gray-400">
                      {post.date}
                    </time>
                    <span className="inline-flex items-center gap-2 text-purple-600 dark:text-purple-400 group-hover:text-purple-700 dark:group-hover:text-purple-300 font-medium">
                      Read More
                      <ArrowRight className="w-4 h-4 transition-transform group-hover:translate-x-1" />
                    </span>
                  </div>
                </div>
              </Link>
            </motion.article>
          ))}
        </motion.div>

        <motion.div variants={fadeInUpVariants} className="text-center">
          <Link
            to="/blog"
            className="inline-flex items-center gap-2 px-8 py-4 bg-purple-600 hover:bg-purple-700 text-white rounded-full font-medium transition-colors"
          >
            View All Posts
            <ArrowRight className="w-4 h-4" />
          </Link>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default Blog;